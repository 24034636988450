@import url("../styles/fonts.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        font-family: "GeneralSans-Regular", sans-serif;
    }
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-2xl;
    }
    h3 {
        @apply text-xl;
    }
    .btn-primary {
        @apply px-6 py-3 bg-blue text-white rounded-full font-medium hover:bg-blue-dark transition-colors duration-300;
    }
    .btn-accent {
        @apply px-6 py-3 bg-white border border-blue text-blue rounded-full font-medium hover:bg-blue hover:text-white transition-colors duration-300;
    }
    .btn-secondary {
        @apply px-6 py-3 bg-white text-black rounded-full font-medium hover:bg-gray transition-colors duration-300 border border-gray;
    }
    .job-shadow {
        box-shadow: 0 7px 14px 0 rgb(8 15 52 / 3%);
    }

    /* Toggl switch */
    input:checked ~ .dot {
        transform: translateX(100%);
        @apply bg-green-500;
    }

    input:checked ~ .checkbg {
        @apply bg-green-300;
    }

    /* React Select outline fix */
    [type="text"]:focus,
    [type="email"]:focus,
    [type="url"]:focus,
    [type="password"]:focus,
    [type="number"]:focus,
    [type="date"]:focus,
    [type="datetime-local"]:focus,
    [type="month"]:focus,
    [type="search"]:focus,
    [type="tel"]:focus,
    [type="time"]:focus,
    [type="week"]:focus,
    [multiple]:focus,
    textarea:focus,
    select:focus {
        @apply ring-0;
    }

    /* WYSIWYG Editor */
    .demo-wrapper {
        width: 100% !important;
        display: block !important;
        margin-bottom: 25px !important;
    }
    .demo-editor {
        height: 360px !important;
        border: 1px solid #ddd !important;
        padding: 5px !important;
        border-radius: 2px !important;
    }
    .demo-wrapper .rdw-option-wrapper {
        border: 0;
    }
    .demo-wrapper .rdw-editor-toolbar {
        @apply py-3;
    }
    .public-DraftStyleDefault-block {
        margin: 0.5rem !important;
    }
    .editor-body .public-DraftStyleDefault-block {
        margin: 0 !important;
    }
    .editor-body h3,
    .editor-body h2 {
        @apply mb-2 mt-4;
    }
    .editor-body ul {
        @apply list-disc pl-4;
    }
    .editor-body ol {
        @apply list-item pl-4;
    }
    .city-list span:last-child span {
        display: none;
    }
    .social {
        position: fixed;
        top: 50%;
        left: 3%;
        transform: rotate(270deg) translateX(-55%);
        transform-origin: 0 0;
        z-index: 9999;
    }
    .social .divide {
        height: 1px;
    }
    .social svg {
        transform: rotate(90deg);
    }
    .social a:hover {
        @apply scale-125 text-blue transition-all;
    }
}
/* .rdw-image-left img {
    float: left;
} */
.rdw-image-center img {
    margin: auto;
}
.rdw-link-decorator-icon {
    display: none!important;
}

#nprogress .bar {
    background-color: rgba(1, 96, 254, 1);
    position: fixed;
    z-index: 103110;
    top: 0;
    left: 0;

    width: 100%;
    height: 0.2rem;
}

/* Blog post styles */
.blog-post {
    font-family: "Inter", sans-serif;
}

.blog-post [role="text"] a {
    text-decoration: underline;
    color: rgba(1, 96, 254, 1);
}

.blog-post h2,
.blog-post h3 {
    font-family: "Inter", sans-serif;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.blog-post p {
    margin-bottom: 1rem;
}

.blog-post ul,
ol {
    margin-bottom: 1rem;
    margin-bottom: 1rem;
}

.blog-post ul li {
    list-style-type: disc;
    margin-left: 1rem;
    padding-left: 1rem;
}

.blog-post ol {
    list-style: decimal;
    margin-left: 1rem;
}

.blog-post ol li {
    padding-left: 1rem;
}

.blog-post blockquote {
    background-color: rgb(243, 245, 254);
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
}
.blog-header {
    background: linear-gradient(rgb(235, 239, 250) 0%, rgba(248, 249, 252, 0) 70%);
}



/* Administracija Sidepanel svg helper */
.svg_active > path{stroke:#035FFE}
.svg > path{stroke:#667085}

.rdw-center-aligned-block > .public-DraftStyleDefault-block > span { 
    text-align: center;
    width: 100%; 
    display: block;
}

.rdw-right-aligned-block > .public-DraftStyleDefault-block > span {
    text-align: right;
    width: 100%;
    display: block;
}

.rdw-justify-aligned-block > .public-DraftStyleDefault-block > span {
    text-align: justify;
    width: 100%;
    display: block;
}

.peer:checked {
    background: #000
}

.peer:checked~.peer-checked\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(28 100 242/var(--tw-text-opacity));
}
.peer:checked~.peer-checked\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgb(28 100 242/var(--tw-border-opacity));
}